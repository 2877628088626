import { FetchPolicy } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { PagingInfo } from '../graphql/__generated__/graphql';
import ListPageState from '../models/ListPageState';
import GraphQLGetVariables from '../types/GraphQLGetVariables';
import useTriggerUpdate from './useTriggerUpdate';

const emptyFunction = () => {};

/**
 * A hook to contain all functionality common to list views, such as fetching data for a table, handling table row clicks, and answering sort/filter requests
 *
 * @template TData The data type that the list page handles
 * @template TVariables The variables to pass to the GraphQL query function
 * @param getMethods Any object with a fetch property method that will fetch data for the list view, and an optional onError property for a callback if the method fails
 * @param getSingleMethods Any object with a fetch property method that will fetch a single piece of data,
 * and an optional onError property for a callback if the method fails
 * @returns Many properties for the list view. The `data` property holds the list of data to display on the list view. The `isLoading` property shows whether the view is
 * loading or not. `onFilterUpdate` is a function to call when the filter has been updated, if any. `refresh` is a function that will
 * refresh the current results. `selectedData` is a single entity that has been selected, if any. `tableProps` is an object of available properties
 * to pass to a paginated table component. `viewState` is a string containing the current state, pulled from ListPageState.js.
 */
// ! TODO: Rename this to `useListPageFunctionality` when GraphQL used everywhere
export default function useGraphQLListPageFunctionality<TData extends object>(
	{
		fetch: getMethod,
		onError: getMethodErrorCallback = emptyFunction,
		refreshSuccessCallback = emptyFunction,
	}: {
		fetch: (
			variables: GraphQLGetVariables,
			fetchPolicy: FetchPolicy,
		) => Promise<{
			PagingInfo: Omit<PagingInfo, 'HasNextPage'>;
			Results: Array<TData>;
		}>;
		onError?: (error?: any) => void;
		refreshSuccessCallback?: () => void;
	},
	initialState?: Partial<PagingInfo> & {
		pageSizeOptions?: number[];
		sorted?: string;
		viewState?: ListPageState;
		selectedUuid?: string;
		fetchDataInitially?: boolean;
	},
) {
	const initialSorted = useRef<GraphQLGetVariables['Sort']>(initialState?.sorted || JSON.stringify([]));
	const canFetchData = useRef(initialState?.fetchDataInitially === undefined ? false : initialState.fetchDataInitially);

	const { willTrigger, triggerUpdate } = useTriggerUpdate();
	const [listData, setListData] = useState<TData[]>([]);
	const [filter, setFilter] = useState<GraphQLGetVariables['Filter']>();
	const [isLoading, setIsLoading] = useState(false);
	const [pages, setPages] = useState(initialState?.TotalPages || 0);
	const [page, setPage] = useState(initialState?.Page || 0);
	const [pageSize, setPageSize] = useState(initialState?.PageSize || 15);
	const [pageSizeOptions] = useState(initialState?.pageSizeOptions || [5, 10, 15]);
	const [selectedUuid, setSelectedUuid] = useState<string | undefined>(initialState?.selectedUuid);
	const [sorted, setSorted] = useState(initialSorted.current);
	const [totalRecordCount, setTotalRecordCount] = useState(initialState?.TotalCount || 0);
	const [viewState, setViewState] = useState(initialState?.viewState || ListPageState.LIST);
	const lastFetchState = useRef<
		| {
				page: number;
				sorted?: string;
				filter?: string;
		  }
		| undefined
	>();
	const areRefreshing = useRef(false);
	const wasPageReturnedDifferentThanWhatPageWasRequested = useRef(false);

	// Have a non-tracked version of view state (for effects that don't need to re-run if it changes)
	const lastViewState = useRef(viewState);
	useEffect(() => {
		lastViewState.current = viewState;
	}, [viewState]);

	// Ensure updates and things like that don't happen after the component is unmounted
	const isMounted = useRef(true);
	useEffect(
		() => () => {
			isMounted.current = false;
		},
		[],
	);

	// If any of the page fetch properties update, go fetch new data
	const delayedFetch = useRef<NodeJS.Timeout | undefined>();
	const fetchData = useRef(
		async ({
			page,
			pageSize,
			filter,
			sorted,
		}: {
			page: number;
			pageSize: number;
			filter?: string;
			sorted?: string;
		}) => {
			// If the component has been unmounted or isn't on the list view, cancel what's going on
			if (
				!isMounted.current ||
				lastViewState.current !== ListPageState.LIST ||
				wasPageReturnedDifferentThanWhatPageWasRequested.current
			) {
				wasPageReturnedDifferentThanWhatPageWasRequested.current = false;
				return;
			}
			if (delayedFetch.current) {
				clearTimeout(delayedFetch.current);
				delayedFetch.current = undefined;
			}
			setIsLoading(true);
			const stringFilter = JSON.stringify(filter || {});
			lastFetchState.current = { page, sorted: sorted || undefined, filter: stringFilter };
			try {
				const response = await getMethod(
					{
						Page: page,
						Size: pageSize,
						Sort: sorted || undefined,
						Filter: filter || undefined,
					},
					areRefreshing.current ? 'network-only' : 'cache-first',
				);
				// If the component has been unmounted or the filter is now different, do nothing with these results
				if (
					!isMounted.current ||
					stringFilter !== lastFetchState.current?.filter ||
					sorted !== lastFetchState.current?.sorted ||
					page !== lastFetchState.current?.page
				) {
					return;
				}
				wasPageReturnedDifferentThanWhatPageWasRequested.current = page !== response.PagingInfo.Page;
				setPage(response.PagingInfo.Page);
				setListData(response.Results);
				setPages(response.PagingInfo.TotalPages);
				setTotalRecordCount(response.PagingInfo.TotalCount);
				setSorted(lastFetchState.current.sorted);

				if (areRefreshing.current) {
					refreshSuccessCallback();
				}
			} catch (error) {
				getMethodErrorCallback(error);
			}
			setIsLoading(false);
			areRefreshing.current = false;
		},
	);
	useEffect(() => {
		if (canFetchData.current) {
			fetchData.current({ page, pageSize, filter: filter || undefined, sorted: sorted || undefined });
		}
		canFetchData.current = true;
	}, [sorted, page, pageSize, filter, willTrigger, getMethod, getMethodErrorCallback, refreshSuccessCallback]);

	// If we get updated to be on the list, de-select any previously selected data
	useEffect(() => {
		if (viewState === ListPageState.LIST) {
			setSelectedUuid(undefined);
		}
	}, [viewState]);

	const onTableUpdate = useCallback(
		({
			sorted: updatedSorted,
			page: updatedPage,
			pageSize: updatedPageSize,
		}: {
			sorted?: GraphQLGetVariables['Sort'];
			page: number;
			pageSize: number;
		}) => {
			setSorted(updatedSorted);
			setPage(updatedPage);
			setPageSize(updatedPageSize);
		},
		[],
	);

	const tableRowProperties = (
		_state?: UseRowSelectState<TData>,
		rowInfo?: UseTableRowProps<TData>,
	): Partial<TableRowProps> & React.HTMLProps<HTMLTableRowElement> => {
		if (!rowInfo) {
			return {};
		}
		return {
			onClick: () => {
				if (rowInfo.original.hasOwnProperty('UU')) {
					setSelectedUuid((rowInfo.original as unknown as { UU: string }).UU);
					setViewState(ListPageState.ADD_EDIT);
				}
			},
			style: {
				cursor: 'pointer',
			},
		};
	};

	return {
		areRefreshing: areRefreshing.current && isLoading,
		data: listData,
		isLoading,
		onFilterUpdate: setFilter,
		refresh: useCallback(
			({ resetSorting = false, resetPage = false } = {}) => {
				areRefreshing.current = true;
				if (resetSorting) {
					setSorted(initialSorted.current);
				}
				if (resetPage) {
					setPage(0);
				}
				triggerUpdate();
			},
			[triggerUpdate],
		),
		reset: useCallback(() => {
			setSorted(initialSorted.current);
			setPage(0);
			// Set a timeout so we can make sure the fetch function gets called (it will be cleared if another state fetch happens shortly)
			delayedFetch.current = setTimeout(
				() =>
					fetchData.current({
						page: 0,
						pageSize,
						filter: filter || undefined,
						sorted: initialSorted.current || undefined,
					}),
				0,
			);
		}, [triggerUpdate, filter]),
		selectedUuid,
		tableProps: {
			onTableUpdate,
			page,
			pages,
			pageSize,
			pageSizeOptions,
			rowProperties: tableRowProperties,
			sorted,
			totalRecordCount,
		},
		viewState: [viewState, setViewState],
	} as const;
}
