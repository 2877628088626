import { ApolloClient, useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { v4 } from 'uuid';
import {
	Ad_Ref_ListDiagnosticStatusesDocument,
	Ad_Ref_ListDiagnosticStatusesQueryVariables,
	Bh_ConceptGetForVisitsDocument,
	Bh_ConceptGetForVisitsQueryVariables,
	Bh_Encounter_DiagnosticInput,
	Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
	Bh_VisitForEditingQuery,
} from '../../graphql/__generated__/graphql';
import {
	ConceptClass,
	DBFilter,
	EncounterDiagnosticStatusCodeUU,
	EncounterTypeWindowUU,
	Paging,
	ReferenceListDB,
	referenceUuids,
} from '../../models';
import { getConceptTestsAndPanelsSearchFilter, getConceptTestsWithinPanelFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getConceptIsActiveForClient, getConceptLocalName } from '../../utils/ModelUtils';
import { LabDiagnosticsDetailsFormValues } from './LabDiagnosticsDetails';
import LabDiagnosticsTableRow from './LabDiagnosticsTableRow';

export type LabDiagnosticsTableFormValues = {
	encounterDiagnostics: Array<{
		UU: string;
		BH_Concept: { UU: string };
		BH_Diagnostic_Status: { UU: string };
		BH_Value: string | null;
	}>;
	addNewEncounterDiagnostic: {
		UU: string;
		BH_Concept: { UU: string };
		BH_Diagnostic_Status: { UU: string };
		BH_Value: string | null;
	};
};

export const convertToLabDiagnosticsTableFormValues: (
	graphqlClient: ApolloClient<object>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => LabDiagnosticsTableFormValues = (graphqlClient, initialData) => {
	const labDiagnosticsEncounterTypeWindow = graphqlClient.readFragment({
		id: EncounterTypeWindowUU.LabDiagnosticDetails,
		fragment: Bh_Encounter_Type_WindowDataForVisitsFragmentDoc,
		fragmentName: 'BH_Encounter_Type_WindowDataForVisits',
	});
	if (!labDiagnosticsEncounterTypeWindow || !initialData) {
		return {
			encounterDiagnostics: [],
			addNewEncounterDiagnostic: {
				UU: v4(),
				BH_Concept: { UU: '' },
				BH_Diagnostic_Status: { UU: EncounterDiagnosticStatusCodeUU.Pending },
				BH_Value: null,
			},
		};
	}
	return {
		encounterDiagnostics: sortBy(
			initialData.BH_Encounters?.filter(
				(encounter) => encounter.BH_Encounter_Type.UU === labDiagnosticsEncounterTypeWindow.BH_Encounter_Type.UU,
			).flatMap((encounter) => encounter.BH_Encounter_DiagnosticList || []) || [],
			'LineNo',
		).map((encounterDiagnostic) => ({
			UU: encounterDiagnostic.UU,
			BH_Concept: { UU: encounterDiagnostic.BH_Concept?.UU || '' },
			BH_Diagnostic_Status: {
				UU: encounterDiagnostic.BH_Diagnostic_Status?.UU || EncounterDiagnosticStatusCodeUU.Pending,
			},
			BH_Value: encounterDiagnostic.BH_Value || null,
		})),
		addNewEncounterDiagnostic: {
			UU: v4(),
			BH_Concept: { UU: '' },
			BH_Diagnostic_Status: { UU: EncounterDiagnosticStatusCodeUU.Pending },
			BH_Value: null,
		},
	};
};

export const constructLabDiagnosticsTableFormDataToSave = (
	data: LabDiagnosticsDetailsFormValues,
): Bh_Encounter_DiagnosticInput[] => {
	// For some reason, the encounter diagnostics is sometimes undefined
	return (
		data.encounterDiagnostics?.map((encounterDiagnostic, index) => ({
			UU: encounterDiagnostic.UU,
			BH_Concept: { UU: encounterDiagnostic.BH_Concept.UU },
			BH_Diagnostic_Status: { UU: encounterDiagnostic.BH_Diagnostic_Status.UU },
			BH_Encounter: { UU: data.labDiagnosticsEncounter.UU },
			BH_Value: encounterDiagnostic.BH_Value || null,
			IsActive: true,
			LineNo: index,
		})) || []
	);
};

const conceptsTestsAndPanelsVariables: Bh_ConceptGetForVisitsQueryVariables = {
	Page: Paging.ALL.page,
	Size: Paging.ALL.size,
	Filter: getConceptTestsAndPanelsSearchFilter().toString(),
};
const diagnosticStatusReferenceListVariables: Ad_Ref_ListDiagnosticStatusesQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.DIAGNOSTIC_STATUSES)
		.up()
		.toString(),
};
export const primeLabDiagnosticsTableData = (graphqlClient: ApolloClient<object>) =>
	Promise.all([
		graphqlClient.query({
			query: Bh_ConceptGetForVisitsDocument,
			variables: conceptsTestsAndPanelsVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListDiagnosticStatusesDocument,
			variables: diagnosticStatusReferenceListVariables,
			fetchPolicy: 'cache-first',
		}),
	]);

type LabDiagnosticsTableProps = {
	readOnly?: boolean;
};

const LabDiagnosticsTable = ({ readOnly }: LabDiagnosticsTableProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { setValue, formState } = useFormContext<LabDiagnosticsTableFormValues>();

	const { fields, append, remove } = useFieldArray<LabDiagnosticsTableFormValues, 'encounterDiagnostics', 'UU'>({
		name: 'encounterDiagnostics',
		keyName: 'UU',
	});

	const conceptTestsAndPanels = useMemo(() => {
		const testsAndPanels =
			graphqlClient
				.readQuery({
					query: Bh_ConceptGetForVisitsDocument,
					variables: conceptsTestsAndPanelsVariables,
				})
				?.BH_ConceptGet.Results.filter((concept) => concept && getConceptIsActiveForClient(concept)) || [];

		testsAndPanels.sort(
			(conceptA, conceptB) =>
				getConceptLocalName(conceptA)
					?.toLowerCase()
					.localeCompare(getConceptLocalName(conceptB)?.toLowerCase() || '') || 0,
		);

		// Return a sorted list of first panels, then tests
		return [
			...testsAndPanels.filter((concept) => concept.bh_concept_class === ConceptClass.LAB_SET),
			...testsAndPanels.filter((concept) => concept.bh_concept_class === ConceptClass.TEST),
		];
	}, [graphqlClient]);

	const diagnosticStatuses = graphqlClient.readQuery({
		query: Ad_Ref_ListDiagnosticStatusesDocument,
		variables: diagnosticStatusReferenceListVariables,
	})?.AD_Ref_ListGet.Results;

	const [resultsToAppend, setResultsToAppend] = useState<LabDiagnosticsTableFormValues['encounterDiagnostics']>([]);
	useUpdateEffect(() => {
		if (resultsToAppend.length) {
			append(resultsToAppend);
			setResultsToAppend([]);
		}
	}, [resultsToAppend, append]);
	const isMounted = useRef(true);
	useEffect(
		() => () => {
			isMounted.current = false;
		},
		[],
	);

	// Handle a new diagnostic test or panel being selected in the ADD row at the bottom of the table
	const addDiagnosticConceptUU = useWatch<LabDiagnosticsTableFormValues, 'addNewEncounterDiagnostic.BH_Concept.UU'>({
		name: 'addNewEncounterDiagnostic.BH_Concept.UU',
	});
	useEffect(() => {
		if (!addDiagnosticConceptUU) {
			return;
		}
		const concept = conceptTestsAndPanels?.find((concept) => concept.UU === addDiagnosticConceptUU);
		if (!concept) {
			return;
		}

		if (concept?.bh_concept_class === ConceptClass.LAB_SET) {
			// If the item selected was a panel (LabSet), we need to append rows for all the tests within that panel
			graphqlClient
				.query({
					query: Bh_ConceptGetForVisitsDocument,
					variables: {
						Page: Paging.ALL.page,
						Size: Paging.ALL.size,
						Filter: getConceptTestsWithinPanelFilter(concept.UU).toString(),
					},
				})
				.then((response) => {
					if (isMounted.current) {
						setResultsToAppend(
							response.data.BH_ConceptGet.Results.filter((concept) => getConceptIsActiveForClient(concept)).map(
								(concept) => ({
									UU: v4(),
									BH_Concept: { UU: concept.UU },
									BH_Diagnostic_Status: { UU: EncounterDiagnosticStatusCodeUU.Pending },
									BH_Value: null,
								}),
							),
						);
					}
				});
		} else {
			// Just add the single test
			append({
				UU: v4(),
				BH_Concept: { UU: concept.UU },
				BH_Diagnostic_Status: { UU: EncounterDiagnosticStatusCodeUU.Pending },
				BH_Value: null,
			});
		}

		// Reset the fields for adding a new row at the bottom of the table
		setValue('addNewEncounterDiagnostic.BH_Concept.UU', '');
	}, [addDiagnosticConceptUU, append, setValue, graphqlClient, conceptTestsAndPanels]);

	return (
		<div className="table-responsive">
			<table className="table bh-table--form">
				<thead>
					<tr>
						<th>{t(uiText.visit.form.diagnostic.TEST)}</th>
						<th>{t(uiText.visit.form.diagnostic.RESULT)}</th>
						<th>{t(uiText.visit.form.diagnostic.REFERENCE_RANGE)}</th>
						<th>{t(uiText.visit.form.diagnostic.STATUS)}</th>
						{!readOnly && <th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>}
					</tr>
				</thead>
				<tbody>
					{fields.map((diagnostic, index) => (
						<LabDiagnosticsTableRow
							key={diagnostic.UU}
							fieldPrefix={`encounterDiagnostics.${index}`}
							readOnly={readOnly}
							remove={() => remove(index)}
							conceptTestsAndPanels={conceptTestsAndPanels}
							diagnosticStatuses={diagnosticStatuses}
						/>
					))}
					{!readOnly && (
						<LabDiagnosticsTableRow
							isAddRow={true}
							fieldPrefix={'addNewEncounterDiagnostic'}
							readOnly={readOnly}
							conceptTestsAndPanels={conceptTestsAndPanels}
							diagnosticStatuses={diagnosticStatuses}
						/>
					)}
				</tbody>
			</table>
			{formState.errors.encounterDiagnostics && formState.errors.encounterDiagnostics.length > 0 && (
				<div>
					{formState.errors.encounterDiagnostics.map((message, index) => (
						<p key={index} className="text-danger">
							{message?.BH_Value?.message}
						</p>
					))}
				</div>
			)}
		</div>
	);
};

export default LabDiagnosticsTable;
