import { useApolloClient } from '@apollo/client';
import { Fragment, useRef } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	Bh_ConceptForEditingDocument,
	Bh_ConceptForEditingQuery,
	Bh_ClientConceptSaveMutationVariables,
	Bh_ClientConceptSaveDocument,
	Bh_ClientConceptDeleteDocument,
} from '../../graphql/__generated__/graphql';
import { ConceptExtraKey } from '../../models';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import {
	getConceptAllExtras,
	getConceptIsActiveForClient,
	getConceptLocalName,
	getConceptParentLabSetName,
	getLabTestResultReferenceRange,
} from '../../utils/ModelUtils';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import { v4 } from 'uuid';

type DiagnosticFormProps = EntityFormProperties;

type DiagnosticFormFields = {
	UU: string;
	localName: string;
	localNameWithoutCustomization: string;
	BH_Display_Name: string;
	parentLabSetName: string;
	Description: string | null;
	bh_concept_class: string;
	BH_OclID: string;
	moh706: string;
	units: string;
	referenceRange: string;
	IsActive: boolean; // Active flag on the main concept from OCL
	isActiveForClient: boolean; // Whether the concept is active in this client or not
	BH_Client_Concept_UU: string | null;
};

const convertToFormFields = (initialData?: Bh_ConceptForEditingQuery['BH_Concept']): DiagnosticFormFields => {
	if (!initialData) {
		return {
			UU: v4(),
			localName: '',
			localNameWithoutCustomization: '',
			BH_Display_Name: '',
			parentLabSetName: '',
			Description: null,
			bh_concept_class: '',
			BH_OclID: '',
			moh706: '',
			units: '',
			referenceRange: '',
			IsActive: true,
			isActiveForClient: true,
			BH_Client_Concept_UU: null,
		};
	}
	return {
		UU: initialData.UU,
		localName: getConceptLocalName(initialData) || '',
		localNameWithoutCustomization: getConceptLocalName(initialData, false) || '',
		BH_Display_Name: initialData.BH_Display_Name || '',
		parentLabSetName: getConceptParentLabSetName(initialData) || '',
		Description: initialData.Description || null,
		bh_concept_class: initialData.bh_concept_class || '',
		BH_OclID: initialData.BH_OclID || '',
		moh706:
			getConceptAllExtras(initialData).find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.MOH_706)
				?.BH_Value || '',
		units:
			getConceptAllExtras(initialData).find((conceptExtra) => conceptExtra.BH_Key === ConceptExtraKey.UNITS)
				?.BH_Value || '',
		referenceRange: getLabTestResultReferenceRange(initialData, false),
		IsActive: initialData.IsActive,
		isActiveForClient: getConceptIsActiveForClient(initialData),
		BH_Client_Concept_UU: initialData.BH_Client_Concepts?.[0]?.UU || null,
	};
};
const getTitle = (uuid?: string) => uiText.diagnostic.title.VIEW;

const DiagnosticForm = ({ uuid, onFinish, renderAsModal }: DiagnosticFormProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { data: initialData } = useSuspenseAsync(
		uuid || 'add-concept',
		async () =>
			(
				await (uuid
					? graphqlClient.query({
							query: Bh_ConceptForEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
					: undefined)
			)?.data.BH_Concept,
	);

	const title = getTitle(uuid);
	const data = useRef(convertToFormFields(initialData));
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { isDirty, errors },
	} = useForm({ defaultValues: data.current });

	const onSubmit: SubmitHandler<DiagnosticFormFields> = async (formData) => {
		/*
		 * The user isn't allowed to modify the concept itself. However, if they change the name or
		 * the "isActive" field, we store that client override in a client concept. So, check if
		 * we need to create/update one, or delete an existing one because it's no longer needed.
		 */
		if (
			formData.localName !== formData.localNameWithoutCustomization ||
			formData.isActiveForClient !== formData.IsActive
		) {
			// There is customization which we need to handle using a client concept
			const clientConcept: Bh_ClientConceptSaveMutationVariables['BH_Client_Concept'] = {
				UU: formData.BH_Client_Concept_UU,
				Name: formData.localName !== formData.localNameWithoutCustomization ? formData.localName : null,
				IsActive: formData.isActiveForClient,
				BH_Concept: {
					UU: formData.UU,
				},
			};

			try {
				const savedClientConceptResult = await graphqlClient.mutate({
					mutation: Bh_ClientConceptSaveDocument,
					variables: { BH_Client_Concept: clientConcept },
				});
				if (!savedClientConceptResult.data) {
					throw savedClientConceptResult.errors;
				}
				toast.success(t(uiText.diagnostic.success.UPDATE));
				onFinish(true, formData.UU);
			} catch (error) {
				exception({ description: `Diagnostic save error: ${error}` });
				toast.error(t(uiText.diagnostic.error.COULD_NOT_SAVE, { error }));
			}
		} else {
			// There is no customization. If there was a client concept, delete it
			if (formData.BH_Client_Concept_UU) {
				try {
					const deletedClientConceptResult = await graphqlClient.mutate({
						mutation: Bh_ClientConceptDeleteDocument,
						variables: { UU: formData.BH_Client_Concept_UU },
					});
					if (!deletedClientConceptResult.data) {
						throw deletedClientConceptResult.errors;
					}
					toast.success(t(uiText.diagnostic.success.UPDATE));
					onFinish(true, formData.UU);
				} catch (error) {
					exception({ description: `Diagnostic save error: ${error}` });
					toast.error(t(uiText.diagnostic.error.COULD_NOT_SAVE, { error }));
				}
			}
		}
	};

	const onHandleRevertName = () => {
		setValue('localName', getValues('localNameWithoutCustomization'), { shouldDirty: true });
	};

	useConfirmRefresh(isDirty);

	const inputs = (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<input type="hidden" {...register('UU')} />
			<input type="hidden" {...register('BH_Client_Concept_UU')} />
			<input type="hidden" {...register('localNameWithoutCustomization')} />
			<input type="hidden" {...register('IsActive')} />
			<Card className="bh-card">
				<Card.Body>
					<Row className="gy-3">
						<Form.Group as={Fragment} controlId="localName">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.LOCAL_NAME)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('localName', { required: true })} />
								{errors?.localName && (
									<span className="text-danger">{t(uiText.diagnostic.error.LOCAL_NAME_REQUIRED)}</span>
								)}
							</Col>
						</Form.Group>

						{data.current.BH_Client_Concept_UU &&
						data.current.localName !== data.current.localNameWithoutCustomization ? (
							<Form.Group as={Fragment} controlId="resetClientConcept">
								<Col xs={4} className="d-flex align-items-center">
									<Button type="button" name="revert" variant="light" onClick={onHandleRevertName}>
										{t(uiText.diagnostic.button.REVERT_NAME)}
									</Button>
								</Col>
								<Col xs={2} />
							</Form.Group>
						) : (
							<Col xs={6} />
						)}

						<Form.Group as={Fragment} controlId="BH_Display_Name">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.SCIENTIFIC_NAME)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('BH_Display_Name')} readOnly={true} />
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId="parentLabSetName">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.PANEL)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('parentLabSetName')} readOnly={true} />
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId="Description">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.DESCRIPTION)}</Form.Label>
							</Col>
							<Col xs={11} className="d-flex align-items-center">
								<Form.Control {...register('Description')} readOnly={true} />
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId="bh_concept_class">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.DIAGNOSTIC_CATEGORY)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('bh_concept_class')} readOnly={true} />
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId="units">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.UNITS)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('units')} readOnly={true} />
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId="BH_OclID">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.CIEL_ID)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('BH_OclID')} readOnly={true} />
							</Col>
						</Form.Group>

						{data.current.referenceRange ? (
							<Form.Group as={Fragment} controlId="referenceRange">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.diagnostic.labels.REFERENCE_RANGE)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control {...register('referenceRange')} readOnly={true} />
								</Col>
							</Form.Group>
						) : (
							<Col xs={6} />
						)}

						<Form.Group as={Fragment} controlId="moh706">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.diagnostic.labels.MOH_706)}</Form.Label>
							</Col>
							<Col xs={5} className="d-flex align-items-center">
								<Form.Control {...register('moh706')} readOnly={true} />
							</Col>
						</Form.Group>

						<Col xs={6} />

						<Form.Group as={Fragment} controlId="isActiveForClient">
							<Col xs={1} className="d-flex align-items-center" />
							<Col xs={5} className="d-flex align-items-center">
								<Form.Check label={t(uiText.diagnostic.labels.ACTIVE)} {...register('isActiveForClient')} />
							</Col>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</Form>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			<Col xs="auto" className="me-auto">
				<Button type="button" name="back" variant="danger" onClick={() => onFinish(false)}>
					{t(uiText.diagnostic.button.BACK)}
				</Button>
			</Col>
			<Col xs="auto">
				<Button
					name="confirm"
					variant="success"
					onClick={() => {
						handleSubmit(onSubmit)();
					}}
				>
					{t(uiText.manageUsers.button.SAVE)}
				</Button>
			</Col>
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="ps-2_5 pb-0_5 bg-white">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<DiagnosticFormProps>({
	loadingLabel: uiText.diagnostic.LOADING,
	getTitle,
})(DiagnosticForm);
