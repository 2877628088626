import { BaseMetadataDB } from './BaseMetadata';
import { ConceptDB } from './Concept';
import { ConceptExtraDB } from './ConceptExtra';

export enum ConceptMappingMapType {
	CONCEPT_SET = 'CONCEPT-SET',
	SAME_AS = 'SAME-AS',
	SAMEAS = 'Same As',
	Q_AND_A = 'Q-AND-A',
}

export interface ConceptMappingDB extends BaseMetadataDB {
	bh_oclid: string;
	bh_map_type: string;
	bh_from_concept_code: string;
	bh_from_concept_name_resolved: string;
	bh_to_concept_code: string;
	bh_to_concept_name_resolved: string;
	bh_concept_extra: ConceptExtraDB;
	bh_concept: ConceptDB;
	'bh_concept::to_bh_concept_id->bh_concept_id': ConceptDB;
}
