import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Dropdown, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import {
	C_BPartnerForPatientsListDocument,
	C_BPartnerForPatientsListQuery,
	ReportOutput,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useIsDocumentActionAvailable from '../../hooks/useIsDocumentActionAvailable';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import useSuspenseProcessAccess from '../../hooks/useSuspenseProcessAccess';
import {
	BusinessPartnerDB,
	businessPartnerGroupName,
	DocAction,
	documentBaseType,
	DocumentStatus,
	OPEN_BALANCE_INVOICE,
	PAYMENT_TRAIL,
} from '../../models';
import DBFilter, { Filter } from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { pageUuid, SERVICE_DEBT_PAGE, VISITS_PAGE } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { getPatientSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getAgeDisplay } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import { generateReportWithGivenParameterValue } from '../../utils/ReportUtil';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReportWindow from '../Reports/ReportWindow';
import PaymentForm from '../ServiceDebt/PaymentForm';
import { setVisitStateForStartingNewVisitWithPatientSelected } from '../Visit/Visits';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import PatientForm from './PatientForm';
import WaiveOpenBalance from './WaiveOpenBalance';

const PaymentServiceOperations = {
	PAY_OPEN_BALANCE: 'payOpenBalance',
} as const;
export type PatientLocationState = { fromSave: boolean; patientUU?: string };

const availableFilters = {
	[uiText.patient.filter.INACTIVE]: DBFilter<BusinessPartnerDB>().property('isactive').equals(false),
	[uiText.patient.filter.ACTIVE]: IS_ACTIVE,
	dateLastVisit: [
		uiText.patient.filter.ALL,
		uiText.patient.filter.TODAY,
		uiText.patient.filter.YESTERDAY,
		uiText.patient.filter.LAST_7_DAYS,
		uiText.patient.filter.LAST_30_DAYS,
	],
} as const;

const PatientListInternal = () => {
	const { t } = useTranslation();
	const { disableWrite } = useActionPrivileges(pageUuid.PATIENTS);
	const graphqlClient = useApolloClient();
	const { state } = useLocation<PatientLocationState | undefined>();
	const history = useHistory();

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: C_BPartnerForPatientsListDocument,
							variables,
							fetchPolicy: 'network-only',
						})
					).data.C_BPartnerGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.patient.error.CANNOT_LOAD));
					}
					exception({ description: `Patient fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			viewState: state?.fromSave ? (state.patientUU ? ListPageState.ADD_EDIT : ListPageState.ADD_EDIT) : undefined,
			selectedUuid: state?.fromSave ? state.patientUU : undefined,
			fetchDataInitially: false,
		},
	);

	// Filter states
	const [inactiveFilter, setInactiveFilter, { reset: resetInactiveFilter }] = useStateWithReset(
		uiText.patient.filter.ACTIVE,
	);
	const [dateLastVisitFilter, setDateLastVisitFilter, { reset: resetDateLastVisitFilter }] = useStateWithReset(
		uiText.patient.filter.ALL,
	);

	// Handle searching and filtering
	useEffect(() => {
		const getDateLastVisitFilter = (): Filter<BusinessPartnerDB> | undefined => {
			const visitFilter = DBFilter<BusinessPartnerDB>().nested('bh_visit::patient_id').property('bh_visitdate');
			switch (dateLastVisitFilter) {
				case uiText.patient.filter.TODAY:
					return visitFilter.equals(new Date(formatDate(new Date()))).up();
				case uiText.patient.filter.YESTERDAY:
					const yesterday = new Date();
					yesterday.setDate(yesterday.getDate() - 1);
					return visitFilter.equals(new Date(formatDate(yesterday))).up();
				case uiText.patient.filter.LAST_7_DAYS:
					const sevenDaysAgo = new Date();
					sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
					return visitFilter
						.isLessThanOrEqualTo(new Date(formatDate(new Date())))
						.property('bh_visitdate')
						.isGreaterThanOrEqualTo(new Date(formatDate(sevenDaysAgo)))
						.up();
				case uiText.patient.filter.LAST_30_DAYS:
					const thirtyDaysAgo = new Date();
					thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
					return visitFilter
						.isLessThanOrEqualTo(new Date(formatDate(new Date())))
						.property('bh_visitdate')
						.isGreaterThanOrEqualTo(new Date(formatDate(thirtyDaysAgo)))
						.up();
				default:
					break;
			}
		};

		let defaultFilter = DBFilter<BusinessPartnerDB>()
			.and(
				DBFilter<BusinessPartnerDB>()
					.nested('c_bp_group')
					.property('name')
					.equals(businessPartnerGroupName.PATIENTS)
					.up(),
			)
			.and(availableFilters[inactiveFilter] as Filter<BusinessPartnerDB>);
		if (searchText) {
			defaultFilter.or(getPatientSearchFilter(searchText));
		}

		let dateFilter = getDateLastVisitFilter();
		if (dateFilter) {
			defaultFilter = defaultFilter.and(dateFilter);
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate, inactiveFilter, dateLastVisitFilter]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetInactiveFilter();
		resetDateLastVisitFilter();
		setSearchText('');
		reset();
	});

	const [paymentBusinessPartner, setPaymentBusinessPartner] = useState<
		C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0] | undefined
	>();
	const [showWaiveOpenBalanceModal, setShowWaiveOpenBalanceModal] = useState(false);
	const [patientToWaiveBalanceFor, setPatientToWaiveBalanceFor] = useState<
		C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0] | undefined
	>(undefined);

	const [canViewPaymentTrail, canViewOpenBalanceInvoice] = useSuspenseProcessAccess([
		PAYMENT_TRAIL,
		OPEN_BALANCE_INVOICE,
	]);

	const [{ loading: isLoadingReport, value: reportResponse }, generateReport] = useCustomAsyncFn(
		async (reportUuid: string, patientUuid: string) =>
			await generateReportWithGivenParameterValue(graphqlClient, reportUuid, patientUuid, ReportOutput.Pdf),
		[graphqlClient],
	);

	const onPaymentServiceOperationClick = async (
		e: React.MouseEvent<any>,
		paymentServiceOperation: string,
		patient: C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0],
	) => {
		e.stopPropagation();
		if (paymentServiceOperation === PAYMENT_TRAIL || paymentServiceOperation === OPEN_BALANCE_INVOICE) {
			generateReport(paymentServiceOperation, patient.UU);
		} else if (paymentServiceOperation === PaymentServiceOperations.PAY_OPEN_BALANCE) {
			setViewState(ListPageState.CUSTOM);
			setPaymentBusinessPartner(patient);
		}
	};

	const onNewVisitOperationClick = async (
		e: React.MouseEvent<any>,
		patient: C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0],
	) => {
		e.stopPropagation();
		history.push({
			pathname: VISITS_PAGE,
			state: setVisitStateForStartingNewVisitWithPatientSelected(patient.UU),
		});
	};

	const canCreatePayments = !useActionPrivileges(pageUuid.PAYMENTS).disableWrite;
	const canCreateVisits = !useActionPrivileges(pageUuid.VISITS).disableWrite;
	const { value: canCompleteInvoices = false } = useIsDocumentActionAvailable(
		documentBaseType.ARInvoice,
		DocumentStatus.DRAFTED,
		DocAction.COMPLETE,
	);
	const canWaiveDebt = canCompleteInvoices && canCreatePayments;
	const waiveOpenBalanceCallback = useCallback(() => {
		setShowWaiveOpenBalanceModal(false);
		setViewState(ListPageState.LIST);
		refresh();
	}, [refresh, setViewState]);

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.patient.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="activeFilter" className="mt-0_5">
									<Form.Check
										checked={inactiveFilter === uiText.patient.filter.INACTIVE}
										onChange={(e) =>
											setInactiveFilter(
												e.target.checked ? uiText.patient.filter.INACTIVE : uiText.patient.filter.ACTIVE,
											)
										}
										label={t(uiText.patient.filter.SHOW_INACTIVE)}
									/>
								</Form.Group>
								<Form.Group controlId="lastVisitDateFilter">
									<Form.Label column>{t(uiText.patient.filter.DATE_LAST_VISIT)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={dateLastVisitFilter}
										onChange={(e) => setDateLastVisitFilter(e.target.value)}
									>
										{availableFilters.dateLastVisit.map((filter) => (
											<option key={filter} value={filter}>
												{t(filter)}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							{reportResponse ? <ReportWindow response={reportResponse} /> : null}
							{showWaiveOpenBalanceModal && patientToWaiveBalanceFor && (
								<WaiveOpenBalance C_BPartnerUU={patientToWaiveBalanceFor.UU} onClose={waiveOpenBalanceCallback} />
							)}
							<BHGraphQLTable<C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.created.LABEL)}</div>,
										accessor: (d) => formatDate(new Date(d.Created)),
									},
									{
										id: 'BH_PatientID',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.patient.patientNumber.BANDA_ABBREVIATED)}</div>
										),
										accessor: (d) => d.BH_PatientID,
										disableSortBy: true,
									},
									{
										id: 'BH_Local_PatientID',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.patient.patientNumber.LOCAL_ABBREVIATED)}</div>
										),
										accessor: (d) => d.BH_Local_PatientID,
										disableSortBy: true,
									},
									{
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.name.LABEL)}</div>,
										accessor: 'Name',
									},
									{
										id: 'age',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.age.LABEL)}</div>,
										accessor: (d) => getAgeDisplay(d.BH_Birthday),
									},
									{
										id: 'gender',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.gender.LABEL)}</div>,
										accessor: (d) => d.bh_gender?.Value,
									},
									{
										id: 'bh_phone',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.phone.LABEL)}</div>,
										accessor: (d) => d.BH_Phone,
										disableSortBy: true,
									},
									{
										id: 'totalopenbalance',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.OPEN_BALANCE)}</div>,
										accessor: (d) => (
											<div className="d-flex justify-content-center">{formatNumber(d.TotalOpenBalance)}</div>
										),
									},
									{
										id: 'payOpenBalance',
										Header: '',
										Cell: (row: CellProps<C_BPartnerForPatientsListQuery['C_BPartnerGet']['Results'][0]>) =>
											([
												canCreatePayments,
												canViewPaymentTrail,
												canCreateVisits,
												canWaiveDebt,
												canViewOpenBalanceInvoice,
											].some((condition) => condition) && (
												<BHDropdownButton
													title={t(uiText.patient.button.ACTION)}
													icon="ellipsis-h"
													variant="bh-light"
													className="py-0"
												>
													{canWaiveDebt && (
														<Dropdown.Item
															onClick={() => {
																setShowWaiveOpenBalanceModal(true);
																setPatientToWaiveBalanceFor(row.cell.row.original);
															}}
															disabled={row.cell.row.original.TotalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="eraser" className="me-2 fa-fw" />
															{t(uiText.patient.button.WAIVE_OPEN_BALANCE)}
														</Dropdown.Item>
													)}
													{canCreatePayments && (
														<Dropdown.Item
															onClick={(e) =>
																onPaymentServiceOperationClick(
																	e,
																	PaymentServiceOperations.PAY_OPEN_BALANCE,
																	row.cell.row.original,
																)
															}
															disabled={row.cell.row.original.TotalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="money-bill-alt" className="me-2 fa-fw" />
															{t(uiText.patient.button.PAY_OPEN_BALANCE)}
														</Dropdown.Item>
													)}
													{canViewPaymentTrail && (
														<Dropdown.Item
															onClick={(e) => onPaymentServiceOperationClick(e, PAYMENT_TRAIL, row.cell.row.original)}
														>
															<FontAwesomeIcon icon="book-medical" className="me-2 fa-fw" />
															{t(uiText.patient.button.VIEW_PAYMENT_TRAIL)}
														</Dropdown.Item>
													)}
													{canViewOpenBalanceInvoice && (
														<Dropdown.Item
															onClick={(e) =>
																onPaymentServiceOperationClick(e, OPEN_BALANCE_INVOICE, row.cell.row.original)
															}
															disabled={row.cell.row.original.TotalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="file-invoice" className="me-2 fa-fw" />
															{t(uiText.patient.button.OPEN_BALANCE_INVOICE)}
														</Dropdown.Item>
													)}
													{canCreateVisits && (
														<Dropdown.Item onClick={(e) => onNewVisitOperationClick(e, row.cell.row.original)}>
															<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
															{t(uiText.patient.button.START_VISIT)}
														</Dropdown.Item>
													)}
												</BHDropdownButton>
											)) ||
											null,
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading || isLoadingReport} title={t(uiText.patient.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : viewState === ListPageState.CUSTOM ? (
				<PaymentForm
					businessPartnerUU={paymentBusinessPartner?.UU}
					onFinish={() => {
						history.push(SERVICE_DEBT_PAGE);
						setPaymentBusinessPartner(undefined);
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			) : (
				<PatientForm
					uuid={selectedUuid}
					onFinish={(refreshData) => {
						if (!refreshData && state && 'fromSave' in state) {
							state.fromSave = false;
						}
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default function PatientList() {
	const { t } = useTranslation();
	return (
		<Suspense
			fallback={
				<Layout>
					<Layout.Header>
						<Layout.Title title={t(uiText.patient.title.LIST)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<LoadSpinner title={t(uiText.patient.LOADING)} />
					</Layout.Body>
				</Layout>
			}
		>
			<PatientListInternal />
		</Suspense>
	);
}
