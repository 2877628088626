import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useService from '../../hooks/useService';
import CodedDiagnosisModel, { CodedDiagnosisDB } from '../../models/CodedDiagnosis';
import DBFilter from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import BHTable from '../BHTable/BHTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import CodedDiagnosisForm from './CodedDiagnosisForm';

const CodedDiagnosis = () => {
	const { t } = useTranslation();

	const [searchText, setSearchText] = useState('');
	const { codedDiagnosisService } = useService();
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<CodedDiagnosisModel, CodedDiagnosisDB>({
		fetch: useCallback((...props) => codedDiagnosisService.get(...props), [codedDiagnosisService]),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.codedDiagnosis.error.COULD_NOT_LOAD));
				}
				exception({ description: `Coded Diagnosis fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Handle searching and filtering
	useEffect(() => {
		let filter = DBFilter<CodedDiagnosisDB>();
		if (searchText) {
			filter.and(codedDiagnosisService.getDefaultSearchFilter(searchText));
		}
		onFilterUpdate(filter);
	}, [searchText, onFilterUpdate, codedDiagnosisService]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.codedDiagnosis.title.LIST)}
							showRefreshIcon
							onRefresh={() => refresh({ resetPage: true })}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHTable<CodedDiagnosisModel, CodedDiagnosisDB>
								data={data}
								columns={[
									{
										id: 'bh_cielname',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.CIEL_NAME)}</div>
										),
										accessor: 'cielName',
									},
									{
										id: 'bh_icd10who',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.ICD10_WHO)}</div>
										),
										accessor: 'icd10',
									},
									{
										id: 'bh_searchterms',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.SEARCH_TERMS)}</div>
										),
										accessor: 'searchTerms',
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.codedDiagnosis.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<CodedDiagnosisForm
					uuid={selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default CodedDiagnosis;
