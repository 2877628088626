import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Bh_ConceptsListPageGetDocument, Bh_ConceptsListPageGetQuery } from '../../graphql/__generated__/graphql';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { getConceptTestSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getConceptIsActiveForClient, getConceptLocalName, getConceptParentLabSetName } from '../../utils/ModelUtils';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import DiagnosticForm from './DiagnosticForm';

const Diagnostics = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const [searchText, setSearchText] = useState('');

	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(await graphqlClient.query({ query: Bh_ConceptsListPageGetDocument, variables, fetchPolicy: 'network-only' }))
					.data.BH_ConceptGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.diagnostic.error.COULD_NOT_LOAD));
				}
				exception({ description: `Concept fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Handle searching and filtering
	useEffect(() => {
		onFilterUpdate(getConceptTestSearchFilter(searchText).toString());
	}, [searchText, onFilterUpdate]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.diagnostic.title.LIST)}
							showRefreshIcon
							onRefresh={() => refresh({ resetPage: true })}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<Bh_ConceptsListPageGetQuery['BH_ConceptGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'local_name',
										Header: () => <div className={'React-table-header'}>{t(uiText.diagnostic.labels.LOCAL_NAME)}</div>,
										accessor: (concept) => getConceptLocalName(concept),
										disableSortBy: true,
									},
									{
										id: 'bh_display_name',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.diagnostic.labels.SCIENTIFIC_NAME)}</div>
										),
										accessor: 'BH_Display_Name',
									},
									{
										id: 'bh_concept_class',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.diagnostic.labels.DIAGNOSTIC_CATEGORY)}</div>
										),
										accessor: 'bh_concept_class',
									},
									{
										id: 'panel',
										Header: () => <div className={'React-table-header'}>{t(uiText.diagnostic.labels.PANEL)}</div>,
										accessor: (concept) => getConceptParentLabSetName(concept),
										disableSortBy: true,
									},
									{
										id: 'isactive',
										Header: () => <div className={'React-table-header'}>{t(uiText.diagnostic.labels.ACTIVE)}</div>,
										accessor: (concept) => (
											<Form.Check
												aria-label={t(uiText.diagnostic.button.ACTIVE)}
												checked={getConceptIsActiveForClient(concept)}
												disabled={true}
											/>
										),
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.diagnostic.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<DiagnosticForm
					uuid={selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default Diagnostics;
