import { BaseMetadataDB } from './BaseMetadata';

export enum ConceptExtraKey {
	HI_ABSOLUTE = 'hi_absolute',
	HI_NORMAL = 'hi_normal',
	LOCAL_NAME = 'local_name',
	LOW_ABSOLUTE = 'low_absolute',
	LOW_NORMAL = 'low_normal',
	MOH_706 = 'MOH-706',
	UNITS = 'units',
}

export interface ConceptExtraDB extends BaseMetadataDB {
	bh_key: string;
	bh_value: string;
}
