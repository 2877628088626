import AuthService from '../services/AuthService';
import ClientService from '../services/ClientService';
import CodedDiagnosisService from '../services/CodedDiagnosisService';
import DashboardService from '../services/DashboardService';

// These must be instantiated here so that any hooks with these as dependencies doesn't continually run
const service = {
	authService: new AuthService(),
	clientService: new ClientService(),
	codedDiagnosisService: new CodedDiagnosisService(),
	dashboardService: new DashboardService(),
} as const;

/**
 * This hook returns services to components so they don't have to be responsible for instantiating them
 * @returns An object of services
 */
export default function useService() {
	return { ...service } as const;
}
