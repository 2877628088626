import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
	C_PaymentForInsurerDonorIncomeDocument,
	C_PaymentForInsurerDonorIncomeQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import { businessPartnerGroupInsurerAndDonorFilter, PaymentDB } from '../../models';
import DBFilter from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { pageUuid } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import StatusBadge from '../StatusBadge/StatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import IncomeForm from './IncomeForm';

const TrackIncome = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const { disableWrite } = useActionPrivileges(pageUuid.TRACK_INCOME);
	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<C_PaymentForInsurerDonorIncomeQuery['C_PaymentGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(
					await graphqlClient.query({
						query: C_PaymentForInsurerDonorIncomeDocument,
						variables,
						fetchPolicy: 'network-only',
					})
				).data.C_PaymentGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.income.error.COULD_NOT_LOAD));
				}
				exception({ description: `Income fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Handle searching and filtering
	useEffect(() => {
		const filter = DBFilter<PaymentDB>()
			.property('isReceipt')
			.equals(true)
			.property('bh_visit_id')
			.isNull()
			.property('payAmt')
			.isGreaterThanOrEqualTo(0);
		const payerFilter = filter
			.nested('c_bpartner')
			.nested('c_bp_group')
			.and(businessPartnerGroupInsurerAndDonorFilter())
			.up();
		if (searchText) {
			payerFilter.property('name').contains(searchText);
		}
		onFilterUpdate(payerFilter.up().toString());
	}, [searchText, onFilterUpdate]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.income.pageDisplay.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_PaymentForInsurerDonorIncomeQuery['C_PaymentGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => <div className={'React-table-header'}>{t(uiText.income.columnHeader.DATE)}</div>,
										accessor: (d) => formatDate(new Date(d.Created)),
									},
									{
										id: 'C_BPartner.name',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.income.columnHeader.INSURER_STROKE_DONOR)}</div>
										),
										accessor: (d) => d.C_BPartner.Name,
									},
									{
										id: 'payamt',
										Header: () => <div className={'React-table-header'}>{t(uiText.income.columnHeader.TOTAL)}</div>,
										accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.PayAmt)}</div>,
									},
									{
										id: 'docStatus',
										Header: () => <div className={'React-table-header'}>{t(uiText.income.columnHeader.STATUS)}</div>,
										accessor: (d) => <StatusBadge documentStatus={d.DocStatus} />,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.income.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<IncomeForm
					uuid={selectedUuid}
					onFinish={() => {
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default TrackIncome;
