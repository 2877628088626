import { BaseMetadataDB } from './BaseMetadata';
import { ClientConceptDB } from './ClientConcept';
import { ConceptExtraDB } from './ConceptExtra';
import { ConceptMappingDB } from './ConceptMapping';
import { OclOriginatingSourceDB } from './OclOriginatingSource';

export enum ConceptClass {
	LAB_SET = 'LabSet',
	TEST = 'Test',
	FINDING = 'Finding',
	MISC = 'Misc',
}

export enum ConceptDataType {
	NUMERIC = 'Numeric',
	TEXT = 'Text',
	CODED = 'Coded',
}

export interface ConceptDB extends BaseMetadataDB {
	bh_concept_uu: string;
	bh_display_name: string;
	bh_concept_class: string;
	bh_concept_extra: ConceptExtraDB;
	bh_concept_mapping: ConceptMappingDB; // child mappings (from this concept to another one)
	'bh_concept_mapping::bh_concept_id->to_bh_concept_id': ConceptMappingDB; // parent mappings (from another concept to this one)
	bh_client_concept: ClientConceptDB;
	bh_ocl_originating_source: OclOriginatingSourceDB;
	bh_source: string;
}
